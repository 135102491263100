.todo-block {
  position: absolute;
  top: calc(31px * 5.35);
  left: calc(31px * 43.85);
  width: calc(31px * 9.2);
  padding: 16px;
}

.todo-action {
  position: relative;
}

.todo-action--pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: rgba(11, 11, 11, 1);
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: todo-pulse 2s infinite;
}

@keyframes todo-pulse {
  0% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(11, 11, 11, 1);
  }

  70% {
    transform: scale(1), translate(-50%, -50%);
    box-shadow: 0 0 0 12px rgba(11, 11, 11, 0);
  }

  100% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(11, 11, 11, 0);
  }
}

.todo-header {
  margin-bottom: 8px;
}

.todo-headline {
  font-weight: 500;
  font-size: 16px;
  color: #a1a1a1;
  letter-spacing: 0;
  line-height: 10px;
  text-transform: uppercase;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f6f6f6;
}
.st1 {
  fill: none;
  stroke: #0b0b0b;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.todo-block .checkbox {
  border: 2px solid var(--light-gray);
  width: 20px;
  height: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
}

.todo-check-text {
  font-weight: 500;
  margin-left: 6px;
}

.todo-description {
  margin-top: 8px;
  line-height: 1.7;
  color: var(--dark-gray);
  font-size: 16px;
}
