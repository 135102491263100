.markie-dummy {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 105;
}

.markie-subtitles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 120px;
  width: 220px;
  background: var(--blue);
  color: var(--white);
  padding: 8px 12px;
  border-radius: 5px;
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
}

.markie-subtitles-right {
  left: auto;
  right: 16px;
}

.markie-wrapper {
  position: absolute;
}

.markie-video-wrapper {
  border-radius: 50% 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 5px solid var(--blue);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.markie-arrow-left::before {
  content: "";
  display: flex;
  background: url(../assets/arrow@1x.png);
  width: 28px;
  height: 26px;
  position: absolute;
  top: -10px;
  left: -10px;
  transform: rotate(5deg);
}

.markie-arrow-right::before {
  content: "";
  display: flex;
  background: url(../assets/arrow@1x.png);
  width: 28px;
  height: 26px;
  position: absolute;
  top: -10px;
  right: -10px;
  transform: rotate(95deg);
}

.markie-video {
  border-radius: 50% 50%;
  transform: scale(2);
}

.markie-mobile .markie-video-wrapper {
  width: 65px;
  height: 65px;
}

@media only screen and (max-width: 576px) {
  .markie-subtitles {
    font-size: 14px;
    width: 200px;
  }

  .markie-subtitles.markie-subtitles-right {
    left: auto;
    right: 12px;
  }
}
@media only screen and (max-width: 443px) {
  .markie-video-wrapper {
    width: 65px;
    height: 65px;
  }

  .markie-arrow-left::before {
    content: "";
    display: flex;
    top: -15px;
    left: -15px;
    transform: rotate(5deg);
  }

  .markie-arrow-right::before {
    content: "";
    display: flex;
    top: -15px;
    right: -15px;
  }
}
