.navigation-wrapper {
  width: 100%;
  padding: 24px;
}

.nav-list-item {
  font-weight: 600;
  margin-top: 32px;
  color: var(--dark-gray);
}

.nav-list-item:hover {
  text-decoration: underline;
}

.nav-list-item--active {
  text-decoration: underline;
}

.nav-list-item + .nav-list-item {
  margin-left: 16px;
}

@media only screen and (max-width: 575px) {
  .navigation-wrapper {
    padding: 16px 24px;
  }
}

@media only screen and (max-width: 490px) {
  .navigation-wrapper {
    flex-flow: column nowrap;
  }

  .navigation {
    margin-top: 16px;
  }
}
