.homepage-header {
  padding: 80px 40px;
  height: 100%;
}

.homepage-headline {
  font-size: 72px;
  width: 100%;
  max-width: 924px;
  text-align: center;
  font-family: Agrandir, sans-serif;
  line-height: 1.2;
  position: relative;
  z-index: 99;
}

.homepage-description {
  margin-top: 16px;
  font-size: var(--fs-lg);
  width: 100%;
  max-width: 480px;
  line-height: 1.65;
  color: var(--dark-gray);
  text-align: center;
  position: relative;
  z-index: 99;
}

.homepage-form {
  margin-top: 28px;
  position: relative;
  z-index: 99;
}

.input-text {
  padding: 12px 16px;
  font-size: 20px;
  color: var(--black);
  font-weight: 500;
  outline: none;
  width: 280px;
  border-radius: 5px;
  background: var(--white);
  border: 2px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  cursor: pointer;
  transition: border ease-in-out 0.3s;
}

.input-text::placeholder {
  color: var(--black);
}

.input-text:active,
.input-text:focus {
  border: 2px solid var(--dark-gray);
}

.input-text:hover {
  border: 2px solid var(--dark-gray);
}

.input-submit {
  background-color: var(--blue);
  padding: 12px 24px;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 16px;
  color: var(--white);
  cursor: pointer;
  border: 2px solid #b8c6ff;
  transition: background ease-in-out 0.3s;
}

.input-submit:hover {
  background-color: #0047c2;
}

.trusted-by {
  margin-top: -8px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--dark-gray);
}

.attention-wrapper {
  padding: 16px;
  margin-top: 16px;
  border-radius: 5px;

  background: var(--light-yellow);
  border: 1px solid var(--dark-yellow);
  text-align: center;
  line-height: 24px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.1);
}

.nav-list-item-bold {
  margin-top: 16px;
}

.nav-list-item-text {
  margin: 0 8px;
  color: var(--black);
  font-weight: 500;
}

@media only screen and (max-width: 1050px) {
  .homepage-headline {
    font-size: 65px;
    max-width: 760px;
  }
}

@media only screen and (max-width: 850px) {
  .homepage-header {
    height: auto;
  }
}

@media only screen and (max-width: 750px) {
  .homepage-headline {
    font-size: 50px;
    max-width: 100%;
  }
  .break {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .homepage-header {
    padding: 20px 24px;
  }
  .homepage-headline {
    font-size: 32px;
  }

  .homepage-description {
    font-size: 22px;
  }

  .input-group {
    flex-flow: column nowrap;
  }

  .attention-wrapper {
    width: 100%;
    max-width: 380px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 445px) {
  .homepage-headline {
    font-size: 26px;
  }
  .homepage-description {
    font-size: 18px;
  }
  .homepage-form {
    margin-top: 24px;
  }
  .input-text {
    padding: 12px;
    font-size: 16px;
  }
  .input-submit {
    padding: 12px 16px;
    font-size: 16px;
  }
}
