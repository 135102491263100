.help-block {
  position: absolute;
  top: calc(31px * 0.35);
  left: calc(31px * 20.85);
  width: 36px;
  height: 36px;
}

.help-block--pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgba(161, 161, 161, 0.25);
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: black-pulse 2s infinite;
}

@keyframes black-pulse {
  0% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(11, 11, 11, 0.7);
  }

  70% {
    transform: scale(1), translate(-50%, -50%);
    box-shadow: 0 0 0 10px rgba(11, 11, 11, 0);
  }

  100% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(11, 11, 11, 0);
  }
}

@media only screen and (max-width: 1250px) {
  .help-block {
    left: calc(31px * 16.85);
  }
}

@media only screen and (max-width: 1000px) {
  .help-block {
    left: calc(31px * 12.9);
    top: calc(31px * 1.35);
  }
}

@media only screen and (max-width: 850px) {
  .help-block {
    display: none;
  }
}
