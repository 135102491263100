.carousel-group {
  position: absolute;
  top: calc(31px * 3.35);
  left: calc(31px * 26.85);
}

.carousel-block {
  width: 36px;
  height: 36px;
}

.carousel-block-group + .carousel-block-group {
  position: relative;
  left: -2px;
}

@media only screen and (max-width: 1250px) {
  .carousel-group {
    left: calc(31px * 22.85);
  }
}

@media only screen and (max-width: 1000px) {
  .carousel-group {
    left: calc(31px * 20.9);
    top: calc(31px * 2.35);
  }
}

@media only screen and (max-width: 750px) {
  .carousel-group {
    left: calc(31px * 16.9);
  }
}

@media only screen and (max-width: 850px) {
  .carousel-group {
    display: none;
  }
}
