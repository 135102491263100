@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/matter/Matter-Regular.woff2") format("woff2"),
    url("./assets/fonts/matter/Matter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/matter/Matter-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/matter/Matter-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/matter/Matter-Bold.woff2") format("woff2"),
    url("./assets/fonts/matter/Matter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Agrandir";
  src: url("./assets/fonts/agrandir/Agrandir-Bold.woff2") format("woff2"),
    url("./assets/fonts/agrandir/Agrandir-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --white: white;
  --black: #0b0b0b;
  --primary: #146aff;
  --primary-dark: #0047c2;
  --primary-light: #deeaff;
  --light-blue: #fafafa;
  --dark-gray: #a1a1a1;
  --light-gray: #e5e6e6;
  --dark-yellow: #ffc000;
  --light-yellow: #fff6db;
  --blue: #146aff;
  --red: #f81414;
  --dark-red: #b90000;
  --light-red: rgba(248, 20, 20, 0.5);
  --fs-xxs: 10px;
  --fs-xs: 14px;
  --fs-sm: 16px;
  --fs-md: 18px;
  --fs-lg: 20px;
  --fs-xl: 26px;
}

body {
  background-color: #f7fafc;
  background-image: url(./assets/hero-gradient.png),
    linear-gradient(
      180deg,
      #f7fafc,
      hsla(0, 0%, 100%, 0.01) 18%,
      hsla(0, 0%, 100%, 0.01) 65%,
      #fff
    ),
    radial-gradient(
      circle farthest-corner at 50% 100%,
      transparent,
      rgba(247, 250, 252, 0.6)
    ),
    url(./assets/tile.svg);
  background-position: top left;
  background-size: 31px;
  background-repeat: no-repeat, repeat, repeat, repeat;
  background-attachment: fixed;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.row-nowrap {
  flex-flow: row nowrap;
}

.row-wrap {
  flex-flow: row wrap;
}

.column-nowrap {
  flex-flow: column nowrap;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-flex-start {
  align-self: flex-start;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.spacer {
  margin: 24px 16px;
}

.spacer--sm {
  margin: 8px 0;
}

.spacer--md {
  margin: 12px 0;
}

.block {
  border-radius: 5px;
  background: var(--white);
  border: 2px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  cursor: pointer;

  transition: all ease-in-out 0.3s;
}

.form-block {
  transition: all ease-in-out 0.3s;
}

.block:hover {
  border: 2px solid var(--dark-gray);
  opacity: 1;
}

.form-block:hover {
  opacity: 1;
}

::selection {
  color: var(--white);
  background: var(--blue);
}

.default-icon {
  position: relative;
  z-index: 1;
}
