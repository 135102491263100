.form-block {
  width: calc(31px * 10.2);
  position: absolute;
  top: calc(31px * 4.42);
  left: calc(31px * 4.9);
  cursor: pointer;
}

.input-label {
  margin-bottom: 4px;
  font-weight: 500;
  cursor: pointer;
}

.input-text-form {
  font-size: var(--fs-sm);
  color: var(--black);
  padding: 8px 4px;
  border-radius: 5px;
  border: 2px solid red;
  outline: 2px solid rgba(248, 20, 20, 0.6);
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.input-wrapper-message--error {
  color: red;
  border-bottom: 1px dotted red;
}

.input-wrapper-message--bottom {
  margin-top: 8px;
  font-size: var(--fs-xs);
}

.input-text-form.block:hover {
  border: 2px solid rgba(248, 20, 20, 1);
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper--pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background: rgba(248, 20, 20, 0.25);
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: input-pulse 2s infinite;
}

@keyframes input-pulse {
  0% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(248, 20, 20, 0.7);
  }

  70% {
    transform: scale(1), translate(-50%, -50%);
    box-shadow: 0 0 0 12px rgba(248, 20, 20, 0);
  }

  100% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(248, 20, 20, 0);
  }
}

@media only screen and (max-width: 1000px) {
  .form-block {
    top: calc(31px * 4.42);
    left: calc(31px * 2.9);
  }
}

@media only screen and (max-width: 850px) {
  .form-block {
    display: none;
  }
}
