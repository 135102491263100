.pricing {
  width: 100%;
  max-width: 1120px;
  margin: 80px auto 0;
  height: auto;
}

.pricing-header {
  margin-bottom: 24px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
}

.pricing-headline {
  font-size: 40px;
  text-align: center;
  font-family: Agrandir, sans-serif;
  line-height: 1.2;
  position: relative;
}

.pricing-description {
  margin-top: 8px;
  color: var(--dark-gray);
  text-align: center;
  line-height: 1.25;
}

.pricing-description--small {
  margin-top: 14px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--primary);
}

.pricing-wrapper {
  margin-top: 50px;
}

@media screen and (max-width: 960px) {
  .pricing {
    padding: 0 24px;
    padding-bottom: 40px;
  }
  .pricing-wrapper {
    flex-flow: column nowrap;
    width: 100%;
    margin-top: 0;
  }
}
