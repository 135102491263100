.shield-block {
  position: absolute;
  bottom: calc(31px * 2.45);
  left: calc(31px * 23.9);
  width: 36px;
  height: 36px;
}

@media only screen and (max-width: 1250px) {
  .shield-block {
    bottom: calc(31px * 3.5);
    left: calc(31px * 17.9);
  }
}

@media only screen and (max-width: 1000px) {
  .shield-block {
    left: calc(31px * 14.9);
  }
}

@media only screen and (max-width: 650px) {
  .shield-block {
    display: none;
  }
}
