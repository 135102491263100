.finance-block {
  position: absolute;
  bottom: calc(31px * 5.5);
  left: calc(31px * 5.9);
  width: calc(31px * 10.2);
  width: calc(31px * 11);
}

.finance-block .floating-boarder {
  width: 3px;
  height: 100%;
  background: #8614f8;
  position: absolute;
  top: 0;
  left: 0;
}

.finance-block .icon-wrapper {
  padding: 12px;
}

.card-icon {
  width: 24px;
  height: 24px;
}

.finance-block .checkbox {
  border: 2px solid var(--light-gray);
  width: 20px;
  height: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
}

.finance-block .card-title-and-description {
  border-left: 2px solid transparent;
  padding: 14px 16px 14px 0;
}

.finance-block .card-subheadline {
  font-weight: 400;
  font-size: var(--fs-xs);
  color: var(--dark-gray);
  line-height: 8px;
  margin-top: 6px;
  text-transform: initial;
  letter-spacing: 0;
}

.finance-block .card-headline {
  font-weight: 500;

  width: 100%;
  max-width: 150px;
  margin: 0;
}

.finance-block .amount {
  font-weight: 500;
  padding: 0 16px 0;
}

.receipt {
  margin-left: 8px;
  position: relative;
  z-index: 1;
}

.receipt--pulse {
  position: absolute;
  top: 50%;
  right: 6px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(13, 133, 13, 0.6);
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: green-pulse 2s infinite;
}

@keyframes green-pulse {
  0% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(13, 133, 13, 0.9);
  }

  70% {
    transform: scale(1), translate(-50%, -50%);
    box-shadow: 0 0 0 12px rgba(13, 133, 13, 0);
  }

  100% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(13, 133, 13, 0);
  }
}

@media only screen and (max-width: 1250px) {
  .finance-block {
    bottom: calc(31px * 3.5);
    left: calc(31px * 3.9);
  }
}

@media only screen and (max-width: 1000px) {
  .finance-block {
    left: calc(31px * 0.9);
  }
}

@media only screen and (max-width: 575px) {
  .finance-block {
    display: none;
  }
}
