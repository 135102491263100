#markie-logomark {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: var(--black);
}

#markie-text {
  color: var(--black);
  font-size: 24px;
  font-family: Agrandir, sans-serif;
  font-weight: 500;
  margin-top: 4px;
  margin-left: 8px;
}

@media only screen and (max-width: 575px) {
  .markie-logo {
    transform: scale(0.8);
    transform-origin: center left;
  }
}
