.pricing-tier {
  width: 100%;
  max-width: calc(30% + 10px);
  border: 1px solid var(--light-gray);
  background: var(--white);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.2);
  padding: 24px;
  border-radius: 5px;
  align-self: flex-start;
}

.pricing-tier-highlight {
  background: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary-dark);
}

.tier-header {
  margin-bottom: 16px;
}

.title {
  font-size: var(--fs-xl);
  font-weight: 500;
}

.description {
  margin-top: 6px;
  font-size: var(--fs-sm);
  color: var(--dark-gray);
}

.pricing-tier-highlight .description {
  color: var(--white);
}

.price {
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  padding: 16px 0;
}

.features {
  margin: 8px 0 16px;
}

.features-description {
  font-size: var(--fs-md);

  color: var(--black);
  text-decoration: underline;
  margin-bottom: 8px;
  font-weight: 500;
}

.feature {
  margin: 8px 0;
}

.text {
  margin-left: 8px;
}

.button-invert {
  background: var(--white);
  color: var(--primary);
}

.button-invert:hover {
  color: var(--white);
}

@media screen and (max-width: 960px) {
  .pricing-tier {
    max-width: 100%;
  }

  .pricing-tier + .pricing-tier {
    margin-top: 16px;
  }
}
