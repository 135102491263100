.web-button {
  width: auto;
  font-size: var(--fs-md);
  font-weight: 500;
  border-radius: 5px;
  line-height: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  pointer-events: all;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  transition: background 0.15s ease-in-out;
  white-space: nowrap;
}
.web-button:disabled,
.web-button--disabled {
  cursor: not-allowed;
}

.web-button--stretch {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.web-button--xs {
  padding: 3px 8px;
}

.web-button--sm {
  padding: 10px 12px;
}
.web-button--sm.web-button--icon {
  padding: 8px 12px;
}

.web-button--md {
  padding: 13px 16px;
  font-size: var(--fs-sm);
}

.web-button--md.web-button--icon {
  padding: 13px 12px;
}

.web-button--lg {
  padding: 18px 24px;
}

.web-button--primary {
  background: var(--primary);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.25);
  color: var(--white);
}

.web-button--secondary {
  background: #ffffff;
  border: 1px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  color: var(--black);
}
.web-button.web-button--rounded {
  color: var(--white);
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.2);
  border-radius: 27px;
}
.web-button.web-button--rounded:focus,
.web-button.web-button--rounded:active {
  outline: none;
  border: 1px solid var(--black);
  box-shadow: 0 0 0 3px rgba(11, 11, 11, 0.1);
}
.web-button.web-button--rounded:hover {
  background: rgba(255, 255, 255, 0.1);
}

.web-button--primary-active,
.web-button--primary:focus,
.web-button--primary:active,
.web-button--secondary-active,
.web-button--secondary:focus,
.web-button--secondary:active,
.web-button--plain:focus,
.web-button--plain:active,
.input-select:focus {
  border: 1px solid var(--primary-dark);
  box-shadow: 0 0 0 3px var(--primary-light);
  outline: none;
}

.web-button--primary:hover {
  background: var(--primary-dark);
}
.web-button--primary:disabled {
  background: var(--primary-dark);
}

.web-button--secondary:hover {
  background: #eeeeee;
}
.web-button--secondary:disabled {
  background: #eeeeee;
}

.web-button--plain:hover {
  background: #eeeeee;
}

.web-button--hide {
  display: none;
}

.web-button--extra {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.icon {
  width: 24px;
  height: 24px;
}

.text-with-icon {
  margin-left: 8px;
}

.web-button-with-dropdown-btn,
.web-button-with-dropdown-chevron {
  cursor: pointer;
}

.web-button.web-button-with-dropdown--primary {
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.25);
  border: none;
}
.web-button.web-button-with-dropdown--secondary {
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.05);
  border: 1px solid var(--light-gray);
}

.web-button-options-list {
  position: absolute;
  top: 48px;
  right: 0;
  background: var(--white);
  width: 175px;
  border: 1px solid var(--light-gray);
  box-shadow: 0 1px 1px 0 rgba(11, 11, 11, 0.15);
  border-radius: 5px;
  z-index: 6;
  padding: 8px;
}

.web-button-options-list-item {
  padding: 4px;
  border-radius: 5px;
  color: var(--black);
  position: relative;
}

.web-button-options-list-item--activate {
  color: var(--green);
  background: #f1fef2;
}

.web-button-options-list-item--deactivate {
  color: var(--red);
  background: #fef1f1;
}

.web-button-options-list-item--hide {
  display: none !important;
}

.web-button-options-list-item:hover {
  background: var(--lightest-gray);
}

.web-button-options-list-item-icon {
  width: 24px;
  height: 24px;
}

.web-button-options-list-item-label {
  margin-left: 6px;
  font-weight: normal;
}
.web-button-options-list-item + .web-button-options-list-item {
  margin-top: 4px;
}

.web-button-with-dropdown-btn {
  border-radius: 5px 0 0 5px;
}
.web-button-with-dropdown-btn:disabled {
  background: var(--primary-dark);
}

.web-button-with-dropdown-btn--primary {
  background: var(--primary);
  border: 1px solid transparent;
}
.web-button-with-dropdown-btn--primary:hover {
  background: var(--primary-dark);
}

.web-button-with-dropdown-btn--secondary:hover {
  background: #eeeeee;
}

.web-button-with-dropdown-btn--md {
  padding: 9px 16px 8px;
}

.web-button-with-dropdown-chevron {
  border-radius: 0 5px 5px 0;
}
.web-button-with-dropdown-chevron:disabled {
  background: var(--primary-dark);
}

.web-button-with-dropdown-chevron:hover .flow-chevron-wrapper {
  transform: rotate(-180deg);
}

.flow-chevron-wrapper {
  transition: transform ease-in-out 0.3s;
}

.web-button-with-dropdown-chevron--primary {
  background: var(--primary);
  border-top: 1px solid transparent;
  border-left: 1px solid #7307df;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.web-button-with-dropdown-chevron--primary:hover {
  background: var(--primary-dark);
}
.web-button-with-dropdown-chevron--secondary:hover {
  background: #eeeeee;
}

.web-button-with-dropdown-chevron--secondary {
  border-left: 1px solid var(--light-gray);
}

.web-button-with-dropdown-chevron--md {
  padding: 6px;
}

.web-button-with-dropdown .text {
  font-weight: 500;
}
.web-button-with-dropdown--primary .text {
  color: var(--white);
}
.web-button-with-dropdown--secondary .text {
  color: var(--black);
}

.space-out-left {
  margin-left: 8px;
}
