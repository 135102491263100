.trash-block {
  position: absolute;
  top: calc(31px * 1.35);
  left: calc(31px * 37.85);
  width: 36px;
  height: 36px;
  background: red;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.trash-block:hover {
  border: 2px solid #b70505;
}
