.card-block {
  position: absolute;
  bottom: calc(31px * 4.35);
  left: calc(31px * 36.85);
  width: calc(31px * 10.2);
}

.card-block.card-block-header {
  display: none;
}

.card-error-badge {
  position: relative;
  margin-right: 16px;
}

.card-error-badge--pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 0, 0, 0.25);
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: pulse 2s infinite;
}

.icon-wrapper {
  padding: 16px;
}

.card-icon {
  width: 24px;
  height: 24px;
}

.card-title-and-description {
  padding: 14px 16px;
  border-left: 2px solid var(--light-gray);
  transition: all ease-in-out 0.3s;
}

.card-block:hover .card-title-and-description {
  border-left: 2px solid var(--dark-gray);
}

.card-subheadline {
  font-weight: 500;
  font-size: var(--fs-xxs);
  color: var(--dark-gray);
  letter-spacing: 1px;
  line-height: 8px;
  text-transform: uppercase;
}

.card-headline {
  font-weight: 500;
  margin-top: 4px;

  width: 100%;
  max-width: 150px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1), translate(-50%, -50%);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

@media only screen and (max-width: 1250px) {
  .card-block {
    left: calc(31px * 25.9);
  }
}

@media only screen and (max-width: 1000px) {
  .card-block {
    left: calc(31px * 20.9);
  }
}

@media only screen and (max-width: 850px) {
  .card-block {
    left: calc(31px * 12.9);
    bottom: calc(31px * 6.35);
  }
}

@media only screen and (max-width: 575px) {
  .card-block {
    left: calc(31px * 3.9);
    bottom: calc(31px * 4.35);
    opacity: 1;
  }

  .card-block.card-block-header {
    display: flex;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    margin-top: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .card-block-home {
    display: none;
  }
}
