.imprint {
  width: 100%;
  max-width: 960px;
  margin: 80px auto 0;
  height: auto;
  padding-bottom: 48px;
  overflow-y: auto;
}

.imprint-headline {
  font-size: var(--fs-lg);
}

.imprint-description {
  margin-top: 8px;
  color: var(--dark-gray);
}

.imprint-wrapper {
  margin-top: 50px;
}

.imprint-block + .imprint-block {
  margin-top: 24px;
}

.imprint-text {
  margin-top: 8px;
}

.imprint-text-bold {
  font-weight: 500;
}

@media screen and (max-width: 960px) {
  .imprint {
    padding: 0 24px;
  }
}
