.builder-quick-search {
  padding: 4px 8px;
  width: calc(31px * 9.2);
  position: absolute;
  top: calc(31px * 11.35);
  left: calc(31px * 1.85);
}

.command-badges {
  position: relative;
}

.builder-quick-search-text {
  color: var(--dark-gray);
  margin-left: 6px;
}

.command-badges--pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgba(161, 161, 161, 0.25);
  transform-origin: center;
  transform: translate(-50%, -50%);
  z-index: 0;
  animation: gray-pulse 2s infinite;
}

@keyframes gray-pulse {
  0% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(161, 161, 161, 0.7);
  }

  70% {
    transform: scale(1), translate(-50%, -50%);
    box-shadow: 0 0 0 10px rgba(161, 161, 161, 0);
  }

  100% {
    transform: scale(0.95), translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(161, 161, 161, 0);
  }
}

@media only screen and (max-width: 1200px) {
  .builder-quick-search {
    display: none;
  }
}
