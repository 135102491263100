.demo {
  width: 100%;
  max-width: 1120px;
  margin: 80px auto 0;
  overflow-y: auto;
  height: auto;
}

.demo-header {
  margin-bottom: 24px;
}

.demo-headline {
  font-size: 40px;
  text-align: center;
  font-family: Agrandir, sans-serif;
  line-height: 1.2;
  position: relative;
}

.demo-description {
  margin-top: 8px;
  color: var(--dark-gray);
}

.demo-content {
  padding-bottom: 80px;
}

.demo-container {
  margin: 24px 16px;
}

.demo-box {
  width: 100%;
  max-width: 500px;
  padding: 48px;
  border-radius: 5px;
  border: 1px solid var(--light-gray);
  background: var(--light-blue);
}

.demo-box {
  width: 100%;
}

.demo-info {
  font-size: var(--fs-sm);
  margin: 16px 0 0;
}

.pill {
  background: var(--primary-light);
  color: var(--primary);
  border-radius: 3px;
  padding: 2px 4px 2px 6px;
  font-weight: 500;

  border: 1px solid var(--primary);
}

#mrk-ui-pointer {
  cursor: pointer;
}

.tooltip-pulse {
  cursor: pointer;
  animation: tooltip-pulse 2s infinite;
}

@keyframes tooltip-pulse {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
